<template>
    <div class="container rtl">
        <div class="row py-3">
            <div class="col">
                <form @submit.prevent="addcompany" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی کۆمپانیا</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable">
                            </label> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>ناوی کۆمپانیا</label>
                            <input v-model="company.company_name" class="form-control"
                                placeholder="ناوی کۆمپانیا" type="text" required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label>ژمارەی مۆبایلی کۆمپانیا</label>
                            <input v-model="company.company_phone" class="form-control"
                                placeholder="ژمارەی مۆبایلی کۆمپانیا" type="text" required>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                <label>مەخزەن</label>
                            <div class="form-group">
                                <select class="form-control" :disabled="auth.user.user_role == 'company'" v-model="company.store_id">
                                    <option v-for="(item, index) in store" :key="index" :value="item.store_id">
                                        {{item.store_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                           <div class="form-group">
                            <button type="submit" class="btn btn-primary m-2">زیادکردن</button>
                           </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped" id="dataTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ناوی کۆمپانیا</th>
                            <th scope="col">ژمارەی مۆبایلی کۆمپانیا</th>
                            <th scope="col">مەخزەن</th>
                           
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in companys" :key="index">
                            <td>{{item.company_name}}</td>
                            <td>{{item.company_phone}}</td>
                            <td>{{item.store_name}} ({{item.city_name_ku}})</td>
                            <td>
                                <button @click="opencompany(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="opencompany(item , 'update')" type="button" class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی کۆمپانیا</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی کۆمپانیا؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deletecompany">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updatecompany">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی کۆمپانیا</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>ناوی کۆمپانیا</label>
                                    <input v-model="selected_company.company_name" class="form-control form-control-lg"
                                        placeholder="ناوی کۆمپانیا" type="text" required>
                                </div>

                                <div class="col-6 my-1">
                                    <label>ژمارەی مۆبایلی کۆمپانیا</label>
                                    <input v-model="selected_company.company_phone" class="form-control form-control-lg"
                                        placeholder="ناوی کۆمپانیا" type="text" required>
                                </div>
                                <div class="col-6 my-1">
                                    <div class="form-group">
                                    <label>مەخزەن</label>
                                        <select class="form-control form-control-lg" :disabled="auth.user.user_role == 'company'" v-model="selected_company.store_id">
                                            <option v-for="(item, index) in store" :key="index" :value="item.store_id">
                                                {{item.store_name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_company: {},
                company: {
                    store_id: 0
                },
                resetable:false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            companys() {
                return this.$store.getters.company
            } ,
            auth() {
                return this.$store.getters.auth
            } ,
            store() {
                return this.$store.getters.store
            } ,
        },
        methods: {
            getcompanys() {
              $('#dataTable').DataTable().destroy();
                axios.post('company/read.php', {
                    store_id : this.auth.user.store_id,
                    user_role : this.auth.user.user_role == 'company' ? 'company' : null,
                })
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.company = r.data.data
                            this.$nextTick( () =>{
                            $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addcompany() {
                let params = this.company
                axios.post('company/create.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                            this.company = {};
                            }
                            this.getcompanys()
                            this.msg = {
                                model: true,
                                text: 'کۆمپانیا زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                        else {
                            // alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletecompany() {
                let params = {
                    company_id: this.selected_company.company_id
                }
                axios.post('company/delete.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getcompanys()
                            this.msg = {
                                model: true,
                                text: ' کۆمپانیا سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updatecompany() {
                let params = this.selected_company
                axios.post('company/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getcompanys()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'کۆمپانیا نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            opencompany(item, type) {
                this.selected_company = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            if (this.auth.isAuth) {
                this.company.store_id = this.auth.user.store_id
            }
            this.getcompanys()
},
    }
</script>